<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Клиент изменен</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Изменение клиента</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-select
          label="Тип клиента"
          :items="typeClient"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="editedItem.client_type_id"
          hide-details
          class="mb-4"
        ></v-select>
        <v-text-field
          label="Имя"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.name"
        ></v-text-field>
        <v-text-field
          label="Телефон"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.phone"
        ></v-text-field>
        <v-text-field
          label="Адрес"
          v-model="editedItem.address"
        ></v-text-field>
        <v-text-field
          label="Квартира"
          v-model.number="editedItem.flat_number"
        ></v-text-field>
        <v-text-field
          label="Этаж"
          v-model.number="editedItem.floor"
        ></v-text-field>
        <v-menu
          :close-on-content-click="false"
          v-model="dataPicker"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          class="mb-2"
          @change="handleDirty"
        >
          <v-text-field
            slot="activator"
            label="Дата доставки"
            v-model="editedItem.birth_day"
            prepend-icon="event"
            hide-details
            readonly
            @change="handleDirty"
          ></v-text-field>
          <v-date-picker
            v-model="editedItem.birth_day"
            @input="dataPicker = false"
            no-title
            scrollable
            locale="ru-ru"
            first-day-of-week="1"
            @change="handleDirty"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          label="Счет"
          v-model.number="editedItem.bill"
          type="number"
          v-if="isCurrentUserAdmin"
        ></v-text-field>
        <v-text-field
          label="Лимит"
          v-model.number="editedItem.limit"
          type="number"
          v-if="isCurrentUserAdmin"
        ></v-text-field>
        <v-text-field
          label="Скидка"
          v-model.number="editedItem.sale"
          type="number"
        ></v-text-field>
        <v-textarea
          label="Комментарий"
          auto-grow
          v-model="editedItem.comment"
          row-height="12"
        ></v-textarea>
        <v-checkbox
          label="Активность"
          v-model="editedItem.active"
          color="primary"
        ></v-checkbox>
        <v-flex xs12>
          <template v-if="historyShow">
            <p class="title mb-4">История</p>

            <v-data-table
              :headers="headersTableHistory"
              :items="history"
              hide-actions
              no-data-text="Записей не найдено"
              no-results-text="Записей не найдено"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.updated_at }}</td>

                <td>{{ props.item.updated_by_name }}</td>

                <td>{{ props.item.change_contents }}</td>
              </template>
            </v-data-table>
          </template>

          <template v-else>
            <v-btn @click.native="getHistory()">История</v-btn>
          </template>
        </v-flex>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataPicker: false,
      editedItem: {},
      typeClient: [],
      createdSuccess: false,
      history: [],
      historyShow: false,
      isDirty: false,
      headersTableHistory: [
        {
          text: 'Дата',
          align: 'left',
          value: 'updated_at',
          sortable: false,
        },
        {
          text: 'Пользователь',
          align: 'left',
          value: 'updated_by_name',
          sortable: false,
        },
        {
          text: 'Изменения',
          align: 'left',
          value: 'change_contents',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    handleDirty() {
      if (!this.isDirty) {
        this.isDirty = true;
      }
    },
    getHistory() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/history`;
      const propsItem = {
        id: this.item.id,
        type: "clients",
      };
      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.history = response.data;
          this.historyShow = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/client-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeClient = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getItem() {
      this.editedItem = {
        active: this.item.active,
        address: this.item.address,
        bill: this.item.bill,
        birth_day: this.item.birth_day.split('T')[0],
        client_type_id: this.item.client_type_id,
        flat_number: this.item.flat_number,
        floor: this.item.floor,
        limit: this.item.limit,
        name: this.item.name,
        phone: this.item.phone,
        sale: this.item.sale,
        comment: this.item.comment,
      };
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients/${this.item.id}`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  computed: {
    isCurrentUserAdmin() {
      return this.$store.getters.getAuthUserGroup.code === 'admin';
    },
  },
  mounted() {
    this.getClientTypes();
    this.getItem();
  },
};
</script>
