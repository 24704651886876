<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Клиент добавлен</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Добавление клиента</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-select
          label="Тип клиента"
          :items="typeClient"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="editedItem.client_type_id"
          hide-details
          class="mb-4"
        ></v-select>
        <v-text-field
          label="Имя"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.name"
        ></v-text-field>
        <v-text-field
          label="Телефон"
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.phone"
        ></v-text-field>
        <v-text-field
          label="Адрес"
          v-model="editedItem.address"
        ></v-text-field>
        <v-text-field
          label="Подъезд"
          v-model="editedItem.entrance"
        ></v-text-field>
        <v-text-field
          label="Квартира"
          v-model="editedItem.flat_number"
        ></v-text-field>
        <v-text-field
          label="Этаж"
          v-model="editedItem.floor"
        ></v-text-field>
        <v-menu
          :close-on-content-click="false"
          v-model="dataPicker"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          class="mb-4"
        >
          <v-text-field
            slot="activator"
            label="День рождения"
            v-model="editedItem.birth_day"
            prepend-icon="event"
            hide-details
            readonly
            :rules="[v => !!v || 'Заполните поле']"
            clearable
          ></v-text-field>
          <v-date-picker
            v-model="editedItem.birth_day"
            @input="dataPicker = false"
            no-title
            scrollable
            locale="ru-ru"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          label="Счет"
          v-model.number="editedItem.bill"
          type="number"
        ></v-text-field>
        <v-text-field
          label="Лимит"
          v-model.number="editedItem.limit"
          type="number"
        ></v-text-field>
        <v-text-field
          label="Скидка"
          v-model.number="editedItem.sale"
          type="number"
        ></v-text-field>
        <v-textarea
          label="Комментарий"
          auto-grow
          v-model="editedItem.comment"
          row-height="12"
        ></v-textarea>
        <v-checkbox
          label="Активность"
          v-model="editedItem.active"
          color="primary"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dataPicker: false,
      editedItem: {
        active: true,
        address: '',
        bill: 0,
        birth_day: '',
        client_type_id: 1,
        entrance: '',
        flat_number: '',
        floor: '',
        limit: 0,
        name: '',
        phone: '',
        sale: 0,
        comment: '',
      },
      typeClient: [],
      createdSuccess: false,
    };
  },
  methods: {
    getClientTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/client-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeClient = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);

        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getClientTypes();
  },
};
</script>
